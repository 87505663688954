<template>
  <div>
    <v-toolbar outlined>
      <v-toolbar-title>{{
        $vuetify.lang.t("$vuetify.site['" + page + "']")
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      /{{ page }}
    </v-toolbar>
    <br />
    <All v-if="page == 'all'" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: this.$route.params.page,
    };
  },
  watch: {
    $route() {
      this.page = this.$route.params.page;
      console.log(this.page);
    },
  },
  methods: {},
};
</script>
